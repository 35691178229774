/* eslint-disable semi */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import React from "react"

import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { helmetJsonLdProp } from "react-schemaorg"
// eslint-disable-next-line import/no-unresolved
import { useLocation } from "@reach/router"

import { featureSchema } from "../utils/seoData"

const SEO = ({
  lang,
  title,
  date,
  homePage,
  image,
  children,
  authorUrl,
  author,
  keywords,
  description,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle: title
            siteDescription: description
            author
            siteKeywords: keywords
            siteUrl
            logo
            twitterUsername
            defaultImage: cover
            siteTel: tel
            siteEmail: email
          }
        }
      }
    `
  )
  const {
    siteTitle,
    siteDescription,
    twitterUsername,
    siteKeywords,
    logo,
    siteUrl,
    defaultImage,
    siteTel,
    siteEmail,
  } = site.siteMetadata

  const seo = {
    title: title || siteTitle,
    description: description || siteDescription,
    image: image || defaultImage,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || siteKeywords,
    logo,
    twitterUsername,
  }
  const metas = [
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:site_name",
      content: siteTitle,
    },
    {
      name: "title",
      content: seo.title,
    },
    {
      name: "description",
      content: seo.description,
    },
    {
      name: "image",
      content: seo.image,
    },
    {
      property: "og:url",
      content: seo.url,
    },
    {
      property: "og:title",
      content: seo.title,
    },
    {
      property: "og:description",
      content: seo.description,
    },
    {
      property: "Keywords",
      content: seo.Keywords,
    },
    {
      property: "og:image",
      content: seo.image,
    },
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      property: "twitter:site",
      content: seo.twitterUsername,
    },
    {
      property: "twitter:title",
      content: seo.title,
    },
    {
      property: "twitter:description",
      content: seo.description,
    },
    {
      property: "twitter:image",
      content: seo.image,
    },
    {
      property: "msapplication-TileColor",
      content: "#AD7F27",
    },
    {
      property: "theme-color",
      content: "#AD7F27",
    },
  ]
  const scripts = []
  if (homePage) {
    const websiteJsonLd = helmetJsonLdProp({
      "@context": "https://schema.org",
      "@type": "WebSite",
      url: siteUrl,
      name: siteTitle,
      description: siteDescription,
      Keywords: siteKeywords,
    })

    const organizationJsonLd = helmetJsonLdProp({
      "@context": "https://schema.org",
      "@type": "Organization",
      url: siteUrl,
      name: siteTitle,
      description: siteDescription,
      logo: `${siteUrl}${seo.logo}`,
      email: siteEmail,
      telephone: siteTel,
      image: seo.image,
      founder: {
        "@type": "Person",
        name: "Hassan Y. Jallow",
        jobTitle: "CEO",
        image: `${siteUrl}/images/hassan.webp`,
        sameAs: [
          "https://www.linkedin.com/in/hassan-y-jallow-558182a9",
          "https://twitter.com/hyjallow",
          "https://www.instagram.com/hassanyjallow/",
        ],
      },
      address: {
        "@type": "PostalAddress",
        streetAddress: "7-8 Madiba Mall, Brufut Gardens Estate",
        addressCountry: "GM",
      },
      Keywords: siteKeywords,
      sameAs: [
        "https://www.facebook.com/waychitPay",
        "https://www.instagram.com/waychitpay/",
        "https://twitter.com/WaychitPay",
        "https://www.crunchbase.com/organization/waychit",
      ],
      hasOfferCatalog: {
        "@type": "OfferCatalog",
        name: "Waychit Features",
        itemListElement: featureSchema,
      },
    })

    scripts.push(websiteJsonLd, organizationJsonLd)
  }

  if (title && author) {
    const articleJsonLd = helmetJsonLdProp({
      "@context": "https://schema.org",
      "@type": "Article",
      headline: title,
      image,
      datePublished: date,
      author: {
        "@type": "Person",
        name: author,
        url: authorUrl,
      },
    })
    scripts.push(articleJsonLd)

    metas.shift()
    metas.push(
      {
        property: "og:type",
        content: "article",
      },
      {
        property: "article:published_time",
        content: date,
      },
      {
        property: "article:author",
        content: author,
      },
      {
        property: "twitter:label1",
        content: "Reading Time",
      }
    )
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={homePage ? null : `%s | ${siteTitle}`}
      link={[
        {
          rel: "alternate",
          type: "application/rss+xml",
          title: "RSS Feed for waychit",
          href: "/feed.xml",
        },
      ]}
      meta={metas}
      script={scripts}
    >
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: "en",
  description: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  homePage: PropTypes.bool,
  author: PropTypes.string,
  authorUrl: PropTypes.string,
  image: PropTypes.string,
  date: PropTypes.any,
  children: PropTypes.node,
}

export default SEO
