import React, { useState, useEffect } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

import MenueIcon from '../icons/MenuArrow';

const Header = () => {
  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "waychit.png"}) {
      childImageSharp {
        gatsbyImageData(width: 190,placeholder: BLURRED)
      }
    }
  }
`);

  const navListData = [
    {
      index: 1,
      title: 'Home',
      href: '/#home',
    },
    {
      index: 2,
      title: 'Feature',
      href: '/#feature',
    },
    {
      index: 3,
      title: 'About',
      href: '/#about',
    },
    {
      index: 4,
      title: 'Contact',
      href: '/#contact',
    },
  ];
  const [top, setTop] = useState(true);
  const [active, setActive] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const scrollHandler = () => (window.scrollY > 10 ? setTop(false) : setTop(true));
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <nav className={`fixed w-full z-40 transition duration-300 ease-in-out ${!top && 'bg-white backdrop-blur-sm shadow-lg'} px-5`}>
      <div className="container mx-auto md:max-w-md lg:max-w-lg 2xl:max-w-2xl 3xl:max-w-3xl max-w-1/4">
        <div className="flex items-center justify-between h-16 md:h-20">
          <Link itemprop="url" to="/" rel="preload" className="max-width-150px md:max-width-full cursor-pointer flex items-center flex-shrink-0 mr-6 pb-1">
            <GatsbyImage itemprop="logo" image={data.file.childImageSharp.gatsbyImageData} alt="waychit logo" className="w-[75%] md:w-auto" />
          </Link>

          <ul className="items-center hidden space-x-8 lg:flex bg:white">
            {navListData.map((item) => (
              <li key={item.index}>
                <Link
                  to={`${item.href}`}
                  aria-label={item.title}
                  title={item.title}
                  className={`active cursor-pointer font-medium tracking-wide text-text1
                        transition-colors duration-200 hover:text-primary ${
                        active ? 'text-primary' : 'text-text1'
                      }`}
                  onClick={() => setActive(active)}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>

          <div className="lg:hidden">
            <button
              type="button"
              aria-label="Open Menu"
              title="Open Menu"
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <MenueIcon />
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full">
                <div className="p-5 bg-white border rounded shadow-sm">
                  <div className="flex items-center justify-between mb-4">
                    <Link to="/" rel="preload" className="flex items-center flex-shrink-0 mr-6">
                      <GatsbyImage className="cursor-pointer w-32" image={data.file.childImageSharp.gatsbyImageData} alt="waychit logo" />
                    </Link>
                    <div>
                      <button
                        type="button"
                        aria-label="Close Menu"
                        title="Close Menu"
                        className="cursor-pointer p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul>
                      {navListData.map((item) => (
                        <li key={item.index}>
                          <Link
                            to={`${item.href}`}
                            aria-label={item.title}
                            title={item.title}
                            onClick={() => setIsMenuOpen(false)}
                            className="relative flex border-t-0.6 border-solid border-muted min-h-[75px] items-center cursor-pointer font-medium tracking-wide text-text1 transition-colors duration-200 hover:text-primary"
                          >
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
