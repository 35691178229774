/* eslint-disable semi */
/* eslint-disable quotes */
export const blogPageMeta = {
  description:
    "Welcome to Waychit's blog - your ultimate resource for hassle-free payments in The Gambia! Our payment aggregator offers a simple, secure, and convenient platform for businesses to collect and manage payments. Our blog keeps you informed and up-to-date with insights, tips, and news on payment processing.",
  keywords:
    "Payment aggregator, Waychit, Mobile banking, Mobile payment, The Gambia, Cash, Financial tech landscape, Businesses, Individuals, Convenience, Bank accounts, Mobile wallets, Payment methods, Security, Fuel coupons, Cashless fueling, Gas stations, Time-saving, Hassle-free, User experience, Physical cash, Streamline, Game-changer, Platform, Merchants, Range of payment sources, Extra layer of security, Busy parent, Student, Professional, Stress-free, Life-simplifying",
}
export const couponPageMeta = {
  description:
    "Simplify your refueling experience with Waychit’s gas-station-agnostic coupons, redeemable at any Waychit-enabled gas station in The Gambia. Streamline your fueling experience.Share purchased coupons with your loved ones on Waychit",
  keywords:
    "fintech, waychit, payment, pay, get paid, shop, digital payments, receive payment, merchant, customer, reliable, secure, fuel coupon, airtime, deliver, accept payment anytime, anywhere, online payments, buy gas with Waychit, Waychit with fuel stations, purchase fuel coupon, redeem fuel coupon, integrage Waychit with wallets, Waychit mobile app, online and offline transactions, cashless payments in the Gambia, scan to pay, link wallet or bank account, download Waychit app now.",
}
export const featureSchema = [
  {
    "@type": "Offer",
    name: "Purchase fuel coupon via bank or mobile account",
    itemOffered: {
      "@type": "Service",
      name: "Fuel Coupon",
    },
  },
  {
    "@type": "Offer",
    name: "Redeem fuel coupon at any gas station",
    itemOffered: {
      "@type": "Service",
      name: "Fuel Coupon",
    },
  },
  {
    "@type": "Offer",
    name: "share already purchased fuel coupons",
    itemOffered: {
      "@type": "Service",
      name: "Fuel Coupon",
    },
  },
]
